export default function () {
    return <svg preserveAspectRatio="xMidYMid meet" data-bbox="39.022 20.084 121.954 159.831" viewBox="39.022 20.084 121.954 159.831" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true" aria-labelledby="svgcid--2o71rrl3yghw"><defs><style>#comp-kl6n0fgp svg [data-color="1"] fill: #CC3D00;</style></defs><title id="svgcid--2o71rrl3yghw"></title>
    <g>
        <path d="M156.804 39.022c-21.687 0-33.939-.799-54.102-17.941a4.16 4.16 0 0 0-5.404 0C77.135 38.223 64.883 39.022 43.196 39.022a4.172 4.172 0 0 0-4.174 4.174V116c0 27.308 16.69 37.493 44.34 54.371 4.475 2.727 9.28 5.661 14.408 8.901a4.185 4.185 0 0 0 4.458 0c5.127-3.24 9.933-6.175 14.408-8.901 27.65-16.878 44.34-27.063 44.34-54.371V43.195a4.169 4.169 0 0 0-4.172-4.173zM152.63 116c0 22.62-13.483 30.849-40.342 47.242-3.852 2.352-7.952 4.854-12.288 7.569-4.337-2.714-8.437-5.217-12.288-7.569C60.852 146.85 47.37 138.621 47.37 116V47.352c19.739-.159 33.156-1.924 52.63-17.668 19.474 15.745 32.891 17.509 52.63 17.668V116z" fill="#D64000" data-color="1"></path>
        <path d="M98.829 64.029a3.834 3.834 0 0 0-5.423 5.423l14.547 14.547-21.424 21.424-14.547-14.547a3.836 3.836 0 0 0-5.423 5.423l34.518 34.518a3.836 3.836 0 0 0 5.423-5.423l-14.547-14.547 21.424-21.424 14.547 14.547a3.834 3.834 0 0 0 5.423-5.423L98.829 64.029z" fill="#D64000" data-color="1"></path>
        <path d="M71.982 121.559a3.834 3.834 0 0 0-5.423 5.423l3.835 3.835a3.836 3.836 0 0 0 5.423-5.423l-3.835-3.835z" fill="#D64000" data-color="1"></path>
        <path d="M71.982 106.218a3.834 3.834 0 0 0-5.423 5.423l19.176 19.177.095.094a3.836 3.836 0 0 0 5.329-5.517l-19.177-19.177z" fill="#D64000" data-color="1"></path>
        <path d="M127.923 73.288l.094.094a3.836 3.836 0 0 0 5.329-5.517l-3.835-3.835a3.834 3.834 0 1 0-5.423 5.423l3.835 3.835z" fill="#D64000" data-color="1"></path>
        <path d="M127.923 88.629l.094.094a3.836 3.836 0 0 0 5.329-5.517L114.17 64.029a3.834 3.834 0 1 0-5.424 5.423l19.177 19.177z" fill="#D64000" data-color="1"></path>
    </g>
</svg>
}