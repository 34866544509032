export default function () {
    return <svg preserveAspectRatio="xMidYMid meet" data-bbox="23.996 15.583 152.008 168.83" viewBox="23.996 15.583 152.008 168.83" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true"><defs><style>#comp-kl6p06gd svg [data-color="1"]</style></defs>
    <g>
        <path d="M147.568 82.029a4.5 4.5 0 0 0-3.978-2.396h-31.91l17.135-58.28a4.5 4.5 0 0 0-7.948-3.928l-68.089 93.008a4.5 4.5 0 0 0 3.631 7.158h35.323l-17.676 61.073a4.5 4.5 0 0 0 8.042 3.783l65.212-95.783a4.493 4.493 0 0 0 .258-4.635zm-57.015 72.01l11.488-39.696a4.498 4.498 0 0 0-4.323-5.751H65.28l47.637-65.07-11.567 39.341a4.5 4.5 0 0 0 4.318 5.77h29.416l-44.531 65.406z" fill="#D64000" data-color="1"></path>
        <path d="M43.484 99.786a4.982 4.982 0 0 0-4.982-4.982h-9.517a4.982 4.982 0 1 0 0 9.964h9.517a4.981 4.981 0 0 0 4.982-4.982z" fill="#D64000" data-color="1"></path>
        <path d="M171.015 94.804h-9.517a4.982 4.982 0 1 0 0 9.964h9.517a4.982 4.982 0 1 0 0-9.964z" fill="#D64000" data-color="1"></path>
        <path d="M52.716 59.428a4.982 4.982 0 0 0 6.926 0 4.982 4.982 0 0 0 0-6.926L46.189 39a4.982 4.982 0 0 0-6.975 6.976l13.502 13.452z" fill="#D64000" data-color="1"></path>
        <path d="M58.344 140.144a4.98 4.98 0 0 0-5.627 0l-13.503 13.453a4.982 4.982 0 0 0 0 6.975 4.982 4.982 0 0 0 6.975 0l13.453-13.503a4.982 4.982 0 0 0-1.298-6.925z" fill="#D64000" data-color="1"></path>
        <path d="M147.284 140.144a4.983 4.983 0 0 0-6.926 6.925l13.453 13.503a4.982 4.982 0 0 0 6.975 0 4.982 4.982 0 0 0 0-6.975l-13.502-13.453z" fill="#D64000" data-color="1"></path>
        <path d="M147.284 59.428l13.503-13.453A4.982 4.982 0 0 0 153.811 39l-13.453 13.503a4.982 4.982 0 0 0 0 6.926 4.983 4.983 0 0 0 6.926-.001z" fill="#D64000" data-color="1"></path>
    </g>
</svg>
}